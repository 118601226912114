// Commenting just for the sake of it to run a build

import React from "react";
import { Translation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

import { Container } from "@material-ui/core";

import logo from "../styles/whiteLogo.svg";

const styles = (theme) => ({
  appBar: {
    padding: "1.5rem 0",
    boxShadow: "none",
    background: "#326822",
    top: "auto",
    bottom: "42px",
    borderBottom: `.5rem solid #264f1a`,
  },
  toolBar: {
    display: `flex`,
    flexDirection: `column`,
    fontSize: `.9rem`,
    [theme.breakpoints.up("md")]: {
      flexDirection: `row`,
    },
    justifyContent: `space-between`,
    minHeight: "42px",
  },
  link: {
    color: `#fff`,
  },
  ftrLeft: {
    width: `100%`,
    display: `flex`,
    alignItems: `center`,
    flexDirection: `column`,
    textAlign: `center`,
    [theme.breakpoints.up("md")]: {
      width: `50%`,
      textAlign: `left`,
      alignItems: `flex-start`,
    },
  },
  ftrRight: {
    display: `flex`,
    alignItems: `center`,
    flexDirection: `column`,
    textAlign: `center`,
    [theme.breakpoints.up("md")]: {
      flexDirection: `row`,
      marginTop: `-3.5rem`,
    },
  },
  ftrLegal: {
    lineHeight: `1rem`,
    marginLeft: `0rem`,
    marginTop: `0rem`,
    marginBottom: `1rem`,
    [theme.breakpoints.up("md")]: {},
  },
});

class Footer extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <AppBar className={classes.appBar} position="static">
        <Container>
          <Toolbar className={classes.toolBar}>
            <div className={classes.ftrLeft}>
              <img
                src={logo}
                style={{ marginBottom: `1rem` }}
                alt="Homewise"
                width="140"
              />
              <Translation>
                {(t) => (
                  <small className={classes.ftrLegal}>
                    &copy; {new Date().getFullYear()} {t("common:legal1")}
                    <br />
                    {t("common:legal2")}
                  </small>
                )}
              </Translation>
            </div>

            <div className={classes.ftrRight}>
              <ul style={{ padding: `0`, margin: `0` }}>
                <li style={{ display: `inline`, padding: `0rem .75rem` }}>
                  <Link href="./contact" className={classes.link}>
                    Contact
                  </Link>
                </li>
                <li style={{ display: `inline`, padding: `0rem .75rem` }}>
                  <Link href="./terms" className={classes.link}>
                    Terms
                  </Link>
                </li>
                <li style={{ display: `inline`, padding: `0rem .75rem` }}>
                  <Link
                    href="https://thinkhomewise.com/page/privacy"
                    className={classes.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy
                  </Link>
                </li>
              </ul>
              <ul style={{ padding: `0`, margin: `0` }}>
                <li style={{ display: `inline` }}>
                  <IconButton
                    aria-label="instagram"
                    href="https://www.instagram.com/homewise_/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon style={{ color: `#fff` }} />
                  </IconButton>
                </li>
                <li style={{ display: `inline` }}>
                  <IconButton
                    aria-label="twitter"
                    href="https://twitter.com/hihomewise/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterIcon style={{ color: `#fff` }} />
                  </IconButton>
                </li>
                <li style={{ display: `inline` }}>
                  <IconButton
                    aria-label="linked in"
                    href="https://ca.linkedin.com/company/homewise-solutions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon style={{ color: `#fff` }} />
                  </IconButton>
                </li>
              </ul>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

export default withStyles(styles)(Footer);
